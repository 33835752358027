export let SHOW_VEHICLES_WITHIN = 20;
export let POSITION_INTERVAL = 10 * 1000;
export let VEHICLE_LAST_ACTIVE_LIMIT = 2 * 60 * 1000; // 2 mins

export let DEAL_STATUS_PENDING = 'pending';
export let DEAL_STATUS_ACCEPTED = 'accepted';
export let TRIP_STATUS_GOING = 'going';
export let TRIP_STATUS_FINISHED = 'finished';
export let TRIP_STATUS_CANCELED = 'canceled';
export let DEAL_TIMEOUT = 20 * 1000; // 20s

export let DEFAULT_AVATAR = "./assets/img/default.png";

export const environment = {
  production: true,
  appName: 'MuniCab Rider',
  firebase: {
    apiKey: "AIzaSyB2t8ik-hMD8MkN8dLRnmVjYzW_Kp2MRYI",
    authDomain: "sa-safememunicab.firebaseapp.com",
    databaseURL: "https://sa-safememunicab-default-rtdb.firebaseio.com",
    projectId: "sa-safememunicab",
    storageBucket: "sa-safememunicab.appspot.com",
    messagingSenderId: "1044902507898",
    appId: "1:1044902507898:android:99524edc1bad8c44c5d2c3",
    measurementId: "G-8CL2NFB95B"
  },
  langArr: [
    { name: 'English', code: 'en' },
    { name: 'española', code: 'es' },
    { name: 'عربى', code: 'ar' },
    { name: '中文', code: 'cn' }
  ], mapOptions: {
    zoom: 12,
    mapTypeControl: false,
    zoomControl: false,
    fullscreenControl: false,
    streetViewControl: false,
  }
};
